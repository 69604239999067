import React from 'react';
import css from './Button.module.css';
import cls from '../../utils/cls';
import SmallLoader from '../../SmallLoader/SmallLoader';
import {Link} from '../../../server/routes';

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
	React.AnchorHTMLAttributes<HTMLAnchorElement> & {
		secondary?: boolean;
		action?: boolean;
		link?: boolean;
		iconOnly?: boolean;
		checked?: boolean;
		route?: string;
		target?: HTMLAnchorElement['target'];
		href?: HTMLAnchorElement['href'];
		download?: boolean | string;
		params?: {[key: string]: string | undefined};
		disabled?: boolean;
		subText?: string | JSX.Element;
		onClick?: (event: React.MouseEvent) => void;
		className?: string;
		loading?: boolean;
		setRef?: React.RefObject<HTMLButtonElement | HTMLAnchorElement>;
		visibleFocus?: boolean;
	};

const Button: React.FC<ButtonProps> = (p) => {
	const {
		target,
		href,
		download,
		children,
		subText,
		onClick,
		secondary,
		action,
		link,
		iconOnly,
		checked,
		className,
		loading,
		setRef,
		visibleFocus,
		type = 'button',
		title,
		route,
		params,
		...props
	} = p;

	const clsName = cls(
		css.button,
		visibleFocus && css.visibleFocus,
		checked && css.checked,
		secondary ? css.secondary : action ? css.textButton : link ? css.linkButton : css.primary,
		props.disabled && css.disabled,
		iconOnly && css.iconButton,
		className && !!css[className] ? css[className] : className,
		loading && css.loading,
	);

	const content = (
		<>
			<div className={css.primaryFont}>{children}</div>
			{subText && <div className={css.secondaryFont}>{subText}</div>}
		</>
	);

	if (loading) {
		return (
			<div className={clsName}>
				<div className={css.loader}>
					<SmallLoader theme={action || secondary ? 'normal' : 'button'} />
				</div>
				<div className={css.loadedContent}>{content}</div>
			</div>
		);
	}

	if (route) {
		return (
			<Link route={route as any} params={params}>
				<a
					className={clsName}
					onClick={onClick as any}
					ref={setRef as React.RefObject<HTMLAnchorElement>}
					target={target}
					{...props}
				>
					{content}
				</a>
			</Link>
		);
	}

	if (href) {
		return (
			<a
				href={href}
				download={download}
				target={target}
				className={clsName}
				onClick={onClick as any}
				tabIndex={props.tabIndex}
				onKeyDown={props.onKeyDown}
				onFocus={props.onFocus}
				onBlur={props.onBlur}
			>
				<span ref={setRef}>{content}</span>
			</a>
		);
	}

	return (
		<button
			{...props}
			title={title}
			type={type}
			className={clsName}
			onClick={onClick}
			ref={setRef as React.RefObject<HTMLButtonElement>}
		>
			{content}
		</button>
	);
};

export default Button;
