import React, {useState} from 'react';
import {Form, FormikProvider, useFormik} from 'formik';
import css from './LoginForm.module.css';
import {useLoginMutation, User} from '../../generated/types';
import {useUser} from '../useUser';
import {getError} from '../utils/error';
import Input from '../inputs/Input/Input.formik';
import Password from '../inputs/Password/Password.formik';
import Button from '../inputs/Button/Button';
import Checkbox from '../inputs/Checkbox/Checkbox.formik';
import Loader from '../Loader/Loader';

type Props = {
	onSuccess?: () => void;
};

type Values = {
	email: string;
	password: string;
	remember: boolean;
};

const LoginForm: React.FC<Props> = ({onSuccess}) => {
	const [login, {loading}] = useLoginMutation();
	const [error, setError] = useState<any>();
	const {setUser} = useUser();
	const formikBag = useFormik<Values>({
		initialValues: {
			email: '',
			password: '',
			remember: true,
		},
		onSubmit: (values, {setSubmitting}) => {
			login({variables: values})
				.then((result) => {
					if (result?.data?.login) {
						setUser(result.data.login as User);
						if (onSuccess) onSuccess();
					} else {
						setError(result.errors);
						setSubmitting(false);
					}
				})
				.catch((error) => {
					setError(error);
					setSubmitting(false);
				});
		},
	});

	return (
		<div className={css.formWrapper}>
			<div className={css.wrapper}>
				{loading && <Loader withoutLoadingWrapper />}
				<FormikProvider value={formikBag}>
					<div className={css.error}>{getError(error)?.message}</div>
					<Form>
						<div className={css.field}>
							<Input name="email" label="E-mail" />
						</div>
						<div className={css.field}>
							<Password name="password" label="Пароль" autoComplete={'off'} />
						</div>
						<div className={css.button}>
							<Checkbox name={'remember'} label={'Запомнить?'} />

							<Button type={'submit'} disabled={formikBag.isSubmitting}>
								Войти
							</Button>
						</div>
					</Form>
				</FormikProvider>
			</div>
		</div>
	);
};

export default LoginForm;
