import React, {useMemo, useRef, useState} from 'react';
import css from './OverflowSet.module.css';
import popoverCss from '../../controls/PopoverButton/PopoverButton.module.css';
import * as SvgIcon from '../../SvgIcon';
import Button from '../../inputs/Button/Button';
import {PopoverProps} from '../PopoverButton/Popover';
import PopoverButton from '../PopoverButton/PopoverButton';
import cls from '../../utils/cls';
import useObserver from '../../utils/hooks/useObserver';
import usePassiveLayoutEffect from '../../utils/hooks/usePassiveLayoutEffect';

type Props = {
	items?: Array<JSX.Element | null | false | undefined>;
	className?: string;
	moreClassName?: string;
	itemInPopupClassName?: string;
	onChangeIndex?: (index: number) => void;
	selectedMoreButton?: boolean;
	forwardRef?: React.RefObject<HTMLDivElement>;
	withBackdrop?: PopoverProps['withBackdrop'];
};

const OverflowSet: React.FC<Props & React.InputHTMLAttributes<HTMLDivElement>> = ({
	items: itemsProps,
	className,
	selectedMoreButton,
	onChangeIndex,
	moreClassName,
	itemInPopupClassName,
	forwardRef,
	withBackdrop,
	...divProps
}) => {
	const items = useMemo(() => itemsProps?.filter(Boolean), [itemsProps]);
	const hiddenContainerRef = useRef<HTMLDivElement>(null);
	const moreRef = useRef<HTMLButtonElement>(null);
	const defaultActionBoxRef = useRef<HTMLDivElement>(null);
	const actionBoxRef = forwardRef || defaultActionBoxRef;
	const {width: actionBoxWidth} = useObserver(actionBoxRef, {
		throttleTimeout: 10,
	});
	const [displayIndex, setDisplayIndex] = useState(items?.length || 1);

	usePassiveLayoutEffect(() => {
		let sum = 0;
		const container = hiddenContainerRef.current;
		let newDisplayIndex = 0;
		if (actionBoxWidth && container && actionBoxRef.current) {
			const gap = parseInt(window.getComputedStyle(actionBoxRef.current, null).getPropertyValue('gap')) || 0;
			for (let index = 0; index < container.children.length; index++) {
				const item = container.children[index];
				const {width} = item.getBoundingClientRect();

				sum += width + gap;
				if (sum > actionBoxWidth) {
					newDisplayIndex = index === 0 ? 0 : index - 1;
					break;
				} else newDisplayIndex = index;
			}

			if (displayIndex !== newDisplayIndex) {
				setDisplayIndex(newDisplayIndex);
				if (onChangeIndex) onChangeIndex(newDisplayIndex);
			}
		}
	}, [actionBoxWidth, onChangeIndex, displayIndex, items]);

	if (!items?.length) return null;

	return (
		<div {...divProps} ref={actionBoxRef as any} className={className}>
			{items && (
				<>
					{items.slice(0, displayIndex).map((_x, index) => (
						<React.Fragment key={index}>{items[index]}</React.Fragment>
					))}

					{displayIndex < items.length && (
						<PopoverButton
							className={cls(moreClassName)}
							checked={selectedMoreButton}
							popover={
								<div className={popoverCss.popoverButtons}>
									{items.slice(displayIndex).map((_x, index) => (
										<div key={index} className={itemInPopupClassName}>
											{items[displayIndex + index]}
										</div>
									))}
								</div>
							}
							iconOnly
							action
							withBackdrop={withBackdrop}
						>
							<SvgIcon.MoreIcon width={20} height={20} />
						</PopoverButton>
					)}
					<div className={cls(css.hiddenElements, className)} ref={hiddenContainerRef}>
						{items.map((_x, index) => (
							<React.Fragment key={index}>{items[index]}</React.Fragment>
						))}
						<Button action iconOnly setRef={moreRef}>
							<SvgIcon.MoreIcon width={20} height={20} />
						</Button>
					</div>
				</>
			)}
		</div>
	);
};

export default OverflowSet;
