import React, {useEffect} from 'react';

type MaybeRef = React.RefObject<HTMLElement> | null | undefined;

function useOnClickOutside(
	ref: MaybeRef | false | HTMLElement | HTMLCollection | Array<MaybeRef | HTMLElement | null | undefined>,
	handler?: (e: any) => void,
	area?: HTMLElement | MaybeRef,
) {
	useEffect(() => {
		if (!handler) return;
		const listener = (event: any) => {
			// Do nothing if clicking ref's element or descendent elements
			if (!ref) return;
			let elements: Array<HTMLElement | null | undefined>;
			if (Array.isArray(ref)) {
				elements = ref.map((r) => r && (r instanceof HTMLElement ? r : r.current));
			} else {
				elements =
					ref instanceof HTMLCollection
						? ([...ref] as HTMLElement[])
						: [ref && (ref instanceof HTMLElement ? ref : ref.current)];
			}

			if (
				elements.some((element) => {
					if (!element || element.contains(event.target)) {
						return true;
					}
				})
			)
				return;

			handler(event);
		};

		const element = !area ? document : area instanceof HTMLElement ? area : area.current;

		if (!element) return;

		element.addEventListener('mousedown', listener);
		element.addEventListener('touchstart', listener);

		return () => {
			element.removeEventListener('mousedown', listener);
			element.removeEventListener('touchstart', listener);
		};
	}, [ref, handler, area]);
}

export default useOnClickOutside;
