/**
 * Перемешивает массив
 *
 * @param {Array} array
 * @return {Array}
 */

export function shuffleArray<T>(array: T[]): T[] {
	let currentIndex = array.length;
	// While there remain elements to shuffle...
	while (currentIndex !== 0) {
		// Pick a remaining element...
		const randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex--;
		// And swap it with the current element.
		const temporaryValue = array[currentIndex];
		array[currentIndex] = array[randomIndex];
		array[randomIndex] = temporaryValue;
	}
	return array;
}

export function removeArrayElementByIndex<T>(array: T[], index: number): T[] {
	return array.slice(0, index).concat(array.slice(index + 1));
}

export function updateArrayElementByIndex<T>(arrayInit: T[], index: number, item: T): T[] {
	const array = [...arrayInit];
	if (array[index]) array[index] = item;
	else array.push(item);
	return array;
}

/**
 * Формирует массив состоящий из чисел от start до end
 * Если не указан end, то start является end
 *
 * arrayRange(3) => [0, 1, 2, 3]
 * arrayRange(3, 5) => [3, 4, 5]
 */
export function arrayRange(start: number, end?: number): number[] {
	if (end === undefined) {
		end = start;
		start = 0;
	}

	const array: number[] = [];
	for (let i = start; i <= end; i++) {
		array.push(i);
	}

	return array;
}

/**
 * Находит разницу между массивами
 * Напрмиер [1, 2, 3], [2, 3, 5] => [1, 5]
 * @param a1
 * @param a2
 */
export function arrayDiff<T>(a1: T[], a2: T[]): T[] {
	return a1.concat(a2).filter((val) => {
		return !(a1.includes(val) && a2.includes(val));
	});
}

/**
 * Выделяет пересечения между массивами
 *
 * arrayIntersect([1, 2, 3], [2, 4, 6]) => [2]
 */
export function arrayIntersect<T>(array1: T[] | null | undefined, array2: T[] | null | undefined): T[] {
	if (!array1 || !array2) return [];
	return array1.filter((item) => array2.includes(item));
}

/**
 * Есть ли пересечение в массивах
 */
export function isArrayIntersect<T>(array1: T[] | undefined, array2: T[] | undefined): boolean {
	return !!array1 && !!array2 && array1.some((item) => array2.includes(item));
}

/**
 * Группирует элементы в массиве
 */
export function groupArrayBy<T>(array: T[], keyFn: (item: T, index: number) => string | number): PlainObjectOf<T[]> {
	const result: PlainObjectOf<T[]> = {};
	for (let i = 0; i < array.length; i++) {
		const item = array[i];
		const key = keyFn(item, i);
		if (result[key]) {
			result[key].push(item);
		} else {
			result[key] = [item];
		}
	}
	return result;
}

export function indexBy<T, K extends keyof T>(
	array: T[],
	key: K,
): {[key in T[K] extends string | number ? T[K] : never]: T} {
	const result: any = {};
	for (let i = 0; i < array.length; i++) {
		const item = array[i];
		result[item[key] as any] = item;
	}
	return result;
}

/**
 * Поиск ближайшего
 */
export function closestByDistance<T>(array: T[], distanceFn: (item: T) => number | undefined | null): T | undefined {
	if (!array || !array.length) return;
	let minDistance = -1;
	let minItem;
	for (let i = 0; i < array.length; i++) {
		const item = array[i];
		const d = distanceFn(item);
		const distance = typeof d === 'number' ? Math.abs(d) : Infinity;
		if (distance === 0) return item;
		if (minDistance < 0 || distance < minDistance) {
			minDistance = distance;
			minItem = item;
		}
	}
	return minItem;
}

/**
 * Метод для преобразования к массиву
 */
export function ensureArray<T>(item: MaybeArray<T>): T[] {
	if ((item as any) == null) return [];
	if (Array.isArray(item)) return item;
	return [item];
}

/**
 * Метод для исключения полей из объекта по предикату
 */
export function omit<T extends {}>(obj: T, fn: <TKey extends keyof T>(key: TKey, value: T[TKey]) => boolean): T {
	const result = {} as any;
	for (const key in obj) {
		if (fn(key, obj[key])) {
			result[key] = obj[key];
		}
	}
	return result;
}

export function onlyUnique<T>(value: T, index: number, self: T[]) {
	return self.indexOf(value) === index;
}

export function onlyUniqueByProp<T>(array: T[], compareFn: (value: T) => any): T[] {
	const unique: {[key: string]: 1} = {};
	const distinct: T[] = [];
	for (let i = 0; i < array.length; i++) {
		const value = compareFn(array[i]);
		if (!unique[value]) {
			distinct.push(array[i]);
			unique[value] = 1;
		}
	}
	return distinct;
}

export function arraysEqual(a: any, b: any, compareFn?: (arg1: any, arg2: any) => boolean) {
	const defaultCompare = (a: any, b: any) => a === b;
	const compare = compareFn || defaultCompare;

	if (a === b) return true;
	if (a === null || b === null) return false;
	if (a.length !== b.length) return false;

	for (let i = 0; i < a.length; ++i) {
		if (!compare(a[i], b[i])) return false;
	}
	return true;
}

export function removeArrayElements<T>(array: T[], callback: (value: T) => boolean): T[] {
	let i = 0;
	const newArray: T[] = [];
	while (i < array.length) {
		if (!callback(array[i])) {
			newArray.push(array[i]);
		}
		++i;
	}
	return newArray;
}

export function updateArrayElements<T>(array: T[], callback: (value: T) => boolean, newItem: (value: T) => T): T[] {
	return array.map((currentItem) => {
		if (callback(currentItem)) {
			return newItem(currentItem);
		} else {
			return currentItem;
		}
	});
}

export function removeArrayElement<T>(array: T[], index: number): T[] {
	return array.slice(0, index).concat(array.slice(index + 1));
}

export function updateArrayElement<T>(arrayInit: T[], index: number, item: T): T[] {
	let array = [...arrayInit];
	array[index] = item;
	return array;
}

export function insertArrayElement<T>(arr: T[], index: number, el: T): T[] {
	return [...arr.slice(0, index), el, ...arr.slice(index)];
}

export function getFirstArrayItem<T>(array: MaybeArray<T>): T | null {
	array = ensureArray(array);
	return array.length ? array[0] : null;
}

export function flattenDeep<T>(arr1: T[][]): T[] {
	return arr1.reduce((acc, val) => (Array.isArray(val) ? acc.concat(flattenDeep(val as any)) : acc.concat(val)), []);
}

export function objectValues<T>(object: PlainObjectOf<T>): T[] {
	return Object.keys(object).map((key) => object[key]);
}
