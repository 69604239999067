import React from 'react';
import {CommonInputInterface} from '../CommonInputInterface';
import css from './Checkbox.module.css';
import Field from '../Field/Field';
import {CheckIcon} from '../../SvgIcon';
import cls from '../../utils/cls';

interface Props extends CommonInputInterface<boolean> {
	/* маленький размер */
	indeterminate?: boolean;
}

export type CheckboxProps = Omit<
	Omit<React.InputHTMLAttributes<HTMLInputElement>, keyof Props> & Props,
	'underlined' | 'value'
>;

const Checkbox: React.FC<CheckboxProps> = (props) => {
	const {label, checked, indeterminate, className, onChange} = props;

	function handleChange(event: React.MouseEvent) {
		event.stopPropagation();
		if (onChange && !props.disabled) onChange(!checked);
	}

	const hasLabel = !!label;

	return (
		<Field className={className} commonProps={{...props, label: null}}>
			<div
				className={cls(
					css.wrapper,
					indeterminate && css.indeterminate,
					checked && css.checked,
					props.disabled && css.disabled,
				)}
				onClick={handleChange}
				role="checkbox"
				aria-checked={checked ? 'true' : 'false'}
				tabIndex={0}
			>
				<div className={cls(css.checkbox)}>
					{indeterminate ? (
						<div className={css.mark}>
							<div className={css.indeterminateMark} />
						</div>
					) : (
						<div className={css.mark}>
							<CheckIcon width={15} height={15} />
						</div>
					)}
				</div>
				<span className={cls(css.label, !hasLabel && css.emptyLabel)}>{label}</span>
			</div>
		</Field>
	);
};

export default Checkbox;
