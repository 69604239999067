import React, {ChangeEvent, useCallback, useEffect, useState} from 'react';
import {CommonInputInterface} from './CommonInputInterface';
import usePrevious from '../utils/hooks/usePreviews';

/**
 * Хук для отслеживания value инпута
 */
export function useValue(
	props: Pick<CommonInputInterface<string>, 'onChange'> & {value?: string; defaultValue?: string},
) {
	const {onChange, value, defaultValue} = props;
	const [stateValue, setStateValue] = useState((defaultValue ?? value ?? '').toString());

	const resultValue = 'value' in props ? (props.value ?? '').toString() : stateValue;

	const handleChangeByEvent = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			const value = (e && e.target && e.target.value) ?? '';
			if (onChange) onChange(value);
			// NOTE: Для autoFill
			setStateValue(value);
		},
		[onChange],
	);

	const handleChangeValue = useCallback(
		(value: string) => {
			if (onChange) onChange(value);
			setStateValue(value);
		},
		[onChange],
	);

	return {
		value: resultValue,
		onChange: handleChangeByEvent,
		onChangeValue: handleChangeValue,
		setValue: setStateValue,
	};
}

export function useNumberValue(
	props: Pick<React.InputHTMLAttributes<HTMLInputElement>, 'value' | 'defaultValue'> &
		Pick<CommonInputInterface<number>, 'onChange'>,
) {
	const {onChange, value, defaultValue} = props;
	const [stateValue, setStateValue] = useState(parseFloat((defaultValue ?? value ?? '').toString()));

	const resultValue = 'value' in props ? parseFloat((props.value ?? '').toString()) : stateValue;

	const handleChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			const value = parseFloat(e && e.target && e.target.value) ?? 0;
			if (onChange) onChange(value);
			// NOTE: Для autoFill
			setStateValue(value);
		},
		[onChange],
	);

	return {
		value: resultValue,
		onChange: handleChange,
		setValue: setStateValue,
	};
}

export function useJsonValue(
	props: Pick<React.InputHTMLAttributes<HTMLInputElement>, 'value' | 'defaultValue'> &
		Pick<CommonInputInterface<any>, 'onChange'>,
) {
	const {onChange, value, defaultValue} = props;
	const [stateValue, setStateValue] = useState<any>(JSON.stringify(defaultValue) ?? JSON.stringify(value) ?? '');
	const [error, setError] = useState(false);
	const parsedValue = JSON.stringify(value || {});
	const prevParsedValue = usePrevious(parsedValue);

	useEffect(() => {
		if (prevParsedValue !== parsedValue) {
			setStateValue(parsedValue);
		}
	}, [prevParsedValue, parsedValue]);

	const handleChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			const value = (e && e.target && e.target.value) ?? '{}';
			let parsedValue = {};
			setStateValue(value);
			try {
				parsedValue = JSON.parse(value);
				if (onChange) onChange(parsedValue);
				setError(false);
				// NOTE: Для autoFill
			} catch (e) {
				setError(true);
			}
		},
		[onChange],
	);

	return {
		error,
		value: stateValue,
		onChange: handleChange,
		setValue: setStateValue,
	};
}

/**
 * Хук для отслеживания фокуса в инпуте
 */
export function useFocus<T extends Pick<React.HTMLAttributes<HTMLElement>, 'onFocus' | 'onBlur'>>(props: T) {
	const {onFocus, onBlur} = props;
	const [hasFocus, setHasFocus] = useState(false);

	const handleFocus: T['onFocus'] = useCallback(
		(e: any) => {
			if (onFocus) onFocus(e);
			setHasFocus(true);
		},
		[onFocus],
	);

	const handleBlur: T['onBlur'] = useCallback(
		(e: any) => {
			if (onBlur) onBlur(e);
			setHasFocus(false);
		},
		[onBlur],
	);

	return {
		hasFocus,
		onFocus: handleFocus,
		onBlur: handleBlur,
	};
}
