import React from 'react';
import LoginForm from '../LoginForm/LoginForm';
import ModalPopup from '../ModalPopup/ModalPopup';

type Props = {
	onClose: () => void;
};

const LoginPopup: React.FC<Props> = ({onClose}) => {
	return (
		<ModalPopup open={true} onClose={onClose} maxWidth={450}>
			<div className="modal-popup-form">
				<h2>Вход на сайт</h2>
				<p>Для входа на сайта введите логин и пароль.</p>
				<LoginForm onSuccess={onClose} />
			</div>
		</ModalPopup>
	);
};

export default LoginPopup;
