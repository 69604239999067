import React from 'react';
import cls from '../utils/cls';
import css from './SmallLoader.module.css';

type Props = {
	theme?: 'button' | 'normal';
};


const SmallLoader: React.FC<Props> = ({theme}) => {
	// TODO: Expected server HTML to contain a matching <section> in <div>.
	return (
		<section className={css.loaderWrapper}>
			<svg className={css.circular} viewBox="25 25 50 50">
				<circle
					className={cls(css.path, theme === 'button' && css.buttonTheme)}
					cx="50"
					cy="50"
					r="20"
					fill="none"
					strokeWidth="4"
					strokeMiterlimit="10"
				/>
			</svg>
		</section>
	);
};

export default SmallLoader;
