import React from 'react';
import {useField, useFormikContext} from 'formik';
import {default as OriginalInput, InputProps} from './Password';

const Password: React.FC<Omit<InputProps, 'onChange' | 'value'> & {
	name: string;
	onCustomChange?: (value: string | null) => void;
}> = props => {
	const [field, meta] = useField(props as any);
	const {setFieldValue} = useFormikContext();
	const {onCustomChange, ...fieldProps} = props;

	return (
		<OriginalInput
			{...field}
			{...(fieldProps as any)}
			error={meta.touched && meta.error}
			onChange={value => {
				setFieldValue(field.name as never, value);
				if (onCustomChange) onCustomChange(value);
			}}
		/>
	);
};

export default Password;
