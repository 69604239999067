import React, {createContext, useCallback, useRef, useState} from 'react';
import Head from 'next/head';
import css from './Layout.module.css';
import {Html} from 'next/document';
import {Link, Router, SiteRoutes} from '../server/routes';
import List from './controls/List/List';
import {useRouter} from './useRouter';
import Button from './inputs/Button/Button';
import {ArrowLeftIcon, HomeIcon, NavigationIcon} from './SvgIcon';
import useLocalStorage from './utils/hooks/useLocalStorage';
import cls from './utils/cls';
import useOnClickOutside from './useOnClickOutside';
import PopoverButton from './controls/PopoverButton/PopoverButton';
import popoverCss from './controls/PopoverButton/PopoverButton.module.css';
import {useUser} from './useUser';
import useToggle from './utils/hooks/useToggle';
import LoginPopup from './LoginPopup/LoginPopup';
import Avatar from './Avatar/Avatar';

type Props = {
	title?: string;
	description?: string;
	children: React.ReactNode;
	handleBack?: () => void;
};

const menu: Array<{title: string; icon?: React.ReactNode; id: keyof SiteRoutes; routes: Array<keyof SiteRoutes>}> = [
	{title: 'Главная', id: 'index', icon: <HomeIcon />, routes: ['index']},
	{title: 'Расы', id: 'races', icon: <HomeIcon />, routes: ['race', 'races', 'raceInfo', 'subRace', 'subRaces']},
	{
		title: 'Классы',
		id: 'classes',
		icon: <HomeIcon />,
		routes: ['classes', 'class', 'classInfo', 'classSpells', 'subClass'],
	},
	{title: 'Заклинания', id: 'spells', icon: <HomeIcon />, routes: ['spell', 'spells']},
	{title: 'Происхождения', id: 'backgrounds', icon: <HomeIcon />, routes: ['background', 'backgrounds']},
	{title: 'Черты', id: 'feats', icon: <HomeIcon />, routes: ['feat', 'feats']},
	{title: 'Чудовища', id: 'monsters', icon: <HomeIcon />, routes: ['monster', 'monsters', 'monsterInfo']},
	{title: 'Предметы', id: 'items', icon: <HomeIcon />, routes: ['item', 'items']},
];

const Layout: React.FC<Props> = ({description, title, handleBack, children}) => {
	const routing = useRouter();
	const route = (routing?.routeName || 'index') as keyof SiteRoutes;
	const {logoutLoading, logout, ...user} = useUser();
	const [openLogin, toggleLogin] = useToggle(false);

	const [menuExpanded, setMenuExpanded] = useLocalStorage('expandedMenu', true);
	const [menuOpen, setMenuOpen] = useState(false);

	const toggleExpandedHandler = useCallback(() => {
		setMenuExpanded(!menuExpanded);
		setMenuOpen(!menuOpen);
	}, [menuExpanded, setMenuExpanded, menuOpen]);

	const menuRef = useRef<HTMLDivElement>(null);

	useOnClickOutside(menuRef, () => setMenuOpen(false));

	const backButton = (
		<div className={cls(css.backButton, handleBack && css.visibleBackButton)}>
			<Button onClick={handleBack} action iconOnly>
				<ArrowLeftIcon width={24} height={24} />
			</Button>
		</div>
	);

	return (
		<>
			<Head>
				<title>{'D&D5'}</title>
				<meta httpEquiv="content-language" content="ru" />
				<meta name="description" content={description || 'Сайт про классы, заклинания по d&d5'} />
				<link rel="icon" href="/IdeaProjects/dnd/public/favicon.ico" />
			</Head>
			<div className={cls(css.main, !menuExpanded && css.menuCollapsed)}>
				<div className={cls(css.menu, !menuExpanded && css.collapsed, menuOpen && css.menuOpen)} ref={menuRef}>
					<Button action iconOnly onClick={toggleExpandedHandler} className={css.hamburger} tabIndex={0}>
						<NavigationIcon />
					</Button>
					<List
						items={menu}
						onRender={(item) => item.title}
						itemSelected={(item) => item.routes.includes(route)}
						onClick={(item) => Router.pushRoute(item.id)}
						className={css.menuList}
					/>
				</div>
				<div className={css.content}>
					<div className={css.appBar}>
						{backButton}
						<div className={css.title}>{title}</div>
						<div className={css.authButtons}>
							{user.id ? (
								<PopoverButton
									hasChevron
									popover={
										<div className={popoverCss.popoverButtons}>
											<Button loading={logoutLoading} onClick={logout} action>
												Выйти
											</Button>
										</div>
									}
									action
								>
									<Avatar user={user} />
								</PopoverButton>
							) : (
								<>
									{openLogin && <LoginPopup onClose={toggleLogin} />}
									<Button action onClick={toggleLogin}>
										Вход
									</Button>
									<Button action route="registration">
										Регистрация
									</Button>
								</>
							)}
						</div>
					</div>
					<div className={css.content}>{children}</div>
				</div>
			</div>
		</>
	);
};

export default Layout;
