import React, {ChangeEvent, useCallback, useState} from 'react';
import css from '../Input/Input.module.css';
import {CommonInputInterface} from '../CommonInputInterface';
import {useValue, useFocus} from '../inputHooks';
import Field from '../Field/Field';
import {CrossIcon, EyeCloseIcon, EyeOpenIcon} from '../../SvgIcon';
import Button from '../Button/Button';
import fieldCss from '../Field/Field.module.css';
import uid from '../../utils/uid';
import cls from '../../utils/cls';

interface InputInterface extends CommonInputInterface<string> {
	setRef?: React.RefObject<HTMLInputElement>;
}

export type InputProps = InputInterface & Omit<React.InputHTMLAttributes<HTMLInputElement>, keyof InputInterface>;

const Password: React.FC<InputProps> = (props) => {
	const {error, disabled, className, underlined, helperText, onChange: _, setRef, size, ...inputProps} = props;
	const {hasFocus, onBlur, onFocus} = useFocus(props);
	const {value, onChange} = useValue(props);
	const [open, setOpen] = useState(false);

	const handleToggleOpen = useCallback(() => {
		setOpen(!open);
	}, [open]);

	const handleClearValue = useCallback(() => {
		onChange({} as ChangeEvent<HTMLInputElement>);
	}, []);

	const id = props.id || 'Input-Password-' + uid();

	return (
		<Field
			commonProps={props}
			topLabel={!!value || hasFocus}
			className={className}
			underlined={underlined}
			bordered={!underlined}
			buttons={
				<>
					{value?.toString() && !disabled && (
						<Button action iconOnly onClick={handleClearValue} className={fieldCss.actionButton}>
							<CrossIcon width={14} height={14} />
						</Button>
					)}
					<Button action iconOnly onClick={handleToggleOpen} className={fieldCss.actionButton}>
						{open ? <EyeCloseIcon width={14} height={14} /> : <EyeOpenIcon width={14} height={14} />}
					</Button>
				</>
			}
		>
			<input
				id={id}
				disabled={disabled}
				className={cls(css.input, !underlined && css.bordered, hasFocus && css.hasFocus)}
				autocomplete="new-password"
				aria-autocomplete="list"
				{...(inputProps as any)}
				type={open ? 'text' : 'password'}
				onChange={onChange}
				onBlur={onBlur}
				onFocus={onFocus}
				ref={setRef}
				value={value}
			/>
		</Field>
	);
};

export default Password;
