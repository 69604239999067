import React from 'react';
import css from './Avatar.module.css';
import {User} from '../../generated/types';
import cls from '../utils/cls';

type Props = {
	user: User;
	size?: 'size24' | 'size32' | 'size48' | 'size64' | 'size128';
	className?: string;
	icon?: React.ReactNode;
};

type ResultProps = Props & Omit<React.InputHTMLAttributes<HTMLDivElement>, keyof Props>;

const getInitials = (propNames: Array<string | undefined>) => {
	const names = propNames
		.filter(Boolean)
		.map((n) => n!.trim())
		.filter(Boolean) as string[];
	if (!names.length) return 'Н';

	let initials = names[0].substring(0, 1).toUpperCase();

	if (names.length > 1) {
		initials += names[names.length - 1].substring(0, 1).toUpperCase();
	}
	return initials;
};

const Avatar: React.FC<ResultProps> = ({user, size, className, icon, ...props}) => {
	const avatarSize = size?.replace(/\D+/g, '') || '24';

	return (
		<div {...props} className={cls(css.wrapper, css[size || 'size24'], className)}>
			{icon && <div className={css.icon}>{icon}</div>}
			<div className={css.circle}>
				{(user.firstName || user.lastName) && (
					<div className={css.initials}>{getInitials([user.lastName, user.firstName])}</div>
				)}
			</div>
		</div>
	);
};

export default Avatar;
