import React, {ReactNode, useCallback, useMemo, useRef, useState} from 'react';
import ListItem, {ItemBase, ItemOptions, MaybeNested} from './ListItem';
import css from './List.module.css';
import OverflowSet from '../OverflowSet/OverflowSet';
import {removeArrayElementByIndex} from '../../utils/array-utils';
import cls from '../../utils/cls';

type Props<T extends ItemBase> = {
	/* Элементы списка */
	items: T[];
	/* Функция рендера для каждого элемента спсика. Если вернет null элементо не появится */
	onRender?(item: T, options?: ItemOptions): ReactNode | string | null;
	/**
	 *  Режим выделения.
	 *  true и 'single' - одиночное выделение. На выделенном элементе появится полоска
	 *  'multiple' - множественное выледение. Перед каждым элеменом будет чекбокс
	 */
	selectMode?: boolean | 'single' | 'multiple';
	/* Массив выделенных элементов (для контролируемых списков) */
	selectItems?: T[];
	/* Выделенных элемент - альтернатива selectItems (для контролируемых списков) */
	selectItem?: T;
	/* Метод вычисляет долдел ли элемент быть выделен */
	itemSelected?(item: T, options?: ItemOptions): boolean;
	/* Метод вычисляет, должен ли пукнт списка быть раскрыт (если у него есть дети) */
	itemOpen?(item: T, options?: ItemOptions): boolean;
	/* Метод вычисляет, должен ли пункт списка быть помечен, как сфокусированный */
	itemFocused?(item: T, options?: ItemOptions): boolean;
	/* Метод для вычисления. Альтернатива ключам элементов */
	itemToPrimaryKey?(item: T): string;
	/* Вызывается при нажатии на элемент */
	onClick?(item: T, index: number): void;

	/* Список ветикальный (по умолчанию - горизинтальный) */
	horizontal?: boolean;
	/* Список "свернут". Влияет на поведеение вложенных списков */
	collapsed?: boolean;
	/* Список должен быть обрезан и непоместившиеся элементы будут доступны в поповере с тремя точками */
	overflow?: boolean;

	/* Класс для списка */
	className?: string;
	/* Класс для элемента (не включает чекбокс) */
	itemClassName?: string;
	/* Класс для элемента (включает чекбокс) */
	itemWrapperClassName?: string;

	/* Реф */
	forwardRef?: React.RefObject<HTMLDivElement>;
	/* Технический параметр, указывает текущую вложенность */
	_nestedLevel?: number;
	/* Технический параметр, указывает, что меню в поповере */
	_isPopoverContent?: boolean;
	/* Технический параметр. Если это меню сложенное, тут будет ссылка на родительский элемент */
	_parentRef?: React.RefObject<HTMLButtonElement>;
};

export function defaultItemToPrimaryKey<T>(item: T) {
	if (item === undefined) return null;

	if (typeof item === 'string' || typeof item === 'number' || typeof item === 'boolean') {
		return item.toString();
	}
	if (React.isValidElement(item)) {
		return item.key;
	}

	if (item == null) return 'null';

	if (typeof item === 'object' && ('id' in item || 'code' in item || 'key' in item || 'title' in item)) {
		return (item as any).id || (item as any).code || (item as any).key || (item as any).title;
	}

	return JSON.stringify(item);
}

function findSelectedIndex<T>(items: Array<MaybeNested<T>>, selectedItems: T[], itemToPrimaryKey: (item: T) => string) {
	for (let i = 0; i < items.length; i++) {
		const key = itemToPrimaryKey(items[i]);
		for (let j = 0; j < selectedItems.length; j++) {
			const selectedKey = itemToPrimaryKey(selectedItems[j]);
			if (key === selectedKey) return i;
		}

		if (items[i]?.items) {
			const nestedIndex = findSelectedIndex(
				items[i].items as Array<MaybeNested<T>>,
				selectedItems,
				itemToPrimaryKey,
			);
			if (nestedIndex !== null) return i;
		}
	}

	return null;
}

function List<T extends ItemBase>(props: Props<T> & Omit<React.InputHTMLAttributes<HTMLDivElement>, 'onClick'>) {
	const {
		items,
		tabIndex,
		className,
		itemClassName,
		itemWrapperClassName,
		itemToPrimaryKey: itemToPrimaryKeyProps,
		selectMode,
		onRender,
		onClick,
		selectItems: selectItemsProps,
		selectItem: selectItemProps,
		itemFocused,
		itemSelected,
		itemOpen,
		horizontal,
		forwardRef,
		_nestedLevel,
		collapsed,
		overflow,
		_isPopoverContent,
		_parentRef,
		...divProps
	} = props;

	const itemToPrimaryKey = itemToPrimaryKeyProps || defaultItemToPrimaryKey;

	const [selectedItemsState, setSelectedItemsState] = useState<T[]>([]);

	const handleClick = useCallback(
		(item: T, index: number) => {
			if (!selectItemsProps && !selectItemProps && !itemSelected) {
				if (selectMode === true || selectMode === 'single') {
					if (
						selectedItemsState.length &&
						itemToPrimaryKey(selectedItemsState[0]) === itemToPrimaryKey(item)
					) {
						setSelectedItemsState([]);
					} else {
						setSelectedItemsState([item]);
					}
				} else if (selectMode === 'multiple') {
					const selectedIndex = selectedItemsState.findIndex(
						(itemState) => itemToPrimaryKey(itemState) === itemToPrimaryKey(item),
					);

					setSelectedItemsState(
						selectedIndex > -1
							? removeArrayElementByIndex(selectedItemsState, selectedIndex)
							: [...selectedItemsState, item],
					);
				}
			}

			if (onClick) {
				onClick(item, index);
			}
		},
		[selectItemsProps, itemSelected, selectItemProps, selectMode, selectedItemsState],
	);

	const selectItems = selectItemsProps ? selectItemsProps : selectItemProps ? [selectItemProps] : selectedItemsState;
	const handleGetItemsByIndex = useCallback(
		(index: number) => {
			return items[index] as MaybeNested<T>;
		},
		[items],
	);

	const listItems = items.map((item, index) => {
		const key = itemToPrimaryKey(item);

		return (
			<ListItem
				key={key}
				item={item as unknown as MaybeNested<T>}
				index={index}
				length={items.length}
				selected={
					itemSelected
						? itemSelected(item, {
								index,
								isSubItem: (_nestedLevel || 0) > 0,
								collapsed: !!collapsed,
						  })
						: selectItems.map(itemToPrimaryKey).includes(key)
				}
				itemFocused={itemFocused}
				onRender={onRender}
				className={itemClassName}
				itemWrapperClassName={itemWrapperClassName}
				onClick={handleClick}
				selectMode={selectMode}
				horizontal={horizontal}
				itemToPrimaryKey={itemToPrimaryKey}
				selectItems={selectItems}
				itemSelected={itemSelected}
				listClassName={className}
				_nestedLevel={_nestedLevel}
				collapsed={collapsed}
				_isPopoverContent={_isPopoverContent}
				itemOpen={itemOpen}
				_parentRef={_parentRef}
				getItemByIndex={handleGetItemsByIndex}
			/>
		);
	});

	const defaultWrapperRef = useRef<HTMLDivElement>(null);
	const wrapperRef = forwardRef || defaultWrapperRef;

	const relatedFocusRef = useRef<Element>(null);

	const handleFocus = useCallback((e: React.FocusEvent) => {
		if (wrapperRef.current && wrapperRef.current.childNodes) {
			if (e.target.contains(e.relatedTarget)) {
				(relatedFocusRef.current as HTMLElement)?.focus();
			} else {
				// @ts-ignore
				if (!relatedFocusRef.current) relatedFocusRef.current = e.relatedTarget;
				e.stopPropagation();
				(wrapperRef.current.childNodes.item(0) as HTMLDivElement)?.focus();
			}
		}
	}, []);

	const classList = cls(css.list, !horizontal && css.vertical, className);

	const [menuDisplayIndex, setMenuDisplayIndex] = useState(0);

	const selectIndex = useMemo(() => {
		return findSelectedIndex(items as Array<MaybeNested<T>>, selectItems, itemToPrimaryKey);
	}, [selectItems, items, itemToPrimaryKey]);

	return horizontal && overflow ? (
		<OverflowSet
			{...divProps}
			items={listItems}
			className={classList}
			moreClassName={cls(!!(selectIndex && menuDisplayIndex <= selectIndex) && css.selectedMoreButton)}
			onChangeIndex={setMenuDisplayIndex}
			selectedMoreButton={!!(selectIndex && menuDisplayIndex <= selectIndex)}
			tabIndex={tabIndex != null ? tabIndex : -1}
			forwardRef={wrapperRef}
			onFocus={handleFocus}
			itemInPopupClassName={css.popupItem}
		/>
	) : (
		<div
			{...divProps}
			className={classList}
			ref={wrapperRef}
			tabIndex={tabIndex != null ? tabIndex : -1}
			onFocus={handleFocus}
		>
			{listItems}
		</div>
	);
}

export default List;
