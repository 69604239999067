import React from 'react';
import css from './Loader.module.css';
import SmallLoader from '../SmallLoader/SmallLoader';
import cls from '../utils/cls';

type Props = {
	text?: string;
	withoutLoadingWrapper?: boolean;
};

const Loader: React.FC<Props> = ({text, withoutLoadingWrapper}) => {
	return (
		<div className={cls(!withoutLoadingWrapper && css.loadingWrapper)}>
			<div className={css.loadingContainer}>
				<div className={css.loading}>
					<SmallLoader />
					<span className={css.loadingText}>{text}</span>
				</div>
			</div>
		</div>
	);
};

export default Loader;
