import {GraphQLError} from 'graphql';
import {ApolloError} from '@apollo/client';

export type NormalizedError = {
	code: string;
	message: string;
	networkError?: boolean;
};

type ParsedError = {
	code: string;
	message: string;
};

export function getError(
	apolloError?: ApolloError | readonly GraphQLError[] | null | undefined,
	withNetworkMessage: boolean = true,
): ParsedError | null {
	if (!apolloError) return null;

	if (Array.isArray(apolloError)) {
		return {
			message: apolloError[0]?.message || DEFAULT_ERROR.message,
			code: apolloError[0]?.extensions?.code || '',
		};
	}

	if (apolloError && 'graphQLErrors' in apolloError && apolloError.graphQLErrors?.length) {
		const error = apolloError.graphQLErrors[0];
		return {code: error?.extensions?.code || '', message: error.message};
	}

	const networkError = 'networkError' in apolloError && apolloError.networkError;
	if (networkError && withNetworkMessage) {
		return {
			code: networkError.name,
			message:
				'Произошла ошибка подлючения к серверу. Возможно не доступен сервер или у вас проблемы с интернетом.',
		};
	}

	return {
		message: 'Произошла неизвестная ошибка',
		code: 'UNKNOWN_ERROR',
	};
}

const DEFAULT_ERROR: NormalizedError = {
	message: 'Произошла неизвестная ошибка',
	code: 'UNKNOWN_ERROR',
};

export function getQueryError(errors?: readonly GraphQLError[]): NormalizedError {
	if (!errors || errors.length) return DEFAULT_ERROR;
	return {
		message: errors[0]?.message || DEFAULT_ERROR.message,
		code: errors[0]?.extensions?.code || '',
	};
}

export function getApolloError(error?: ApolloError | null): NormalizedError {
	const err: NormalizedError | null = getError(error, true);
	if (!err || !error) return DEFAULT_ERROR;
	if (error.networkError) err.networkError = true;
	return err;
}
