import React, {useCallback, useEffect, useRef} from 'react';
import css from './ModalPopup.module.css';
import Modal from '../Modal';
import cls from '../utils/cls';
import * as SVGIcons from '../SvgIcon';

type Props = {
	isModal?: boolean;
	onClose?: () => void;
	open: boolean;
	buttons?: any;
	className?: string;
	theme?: string;
	maxWidth?: number;
	header?: string;
};

export function toggleHtmlClass(open: boolean) {
	const documentElement = document.documentElement;
	if (!documentElement) return;
	if (open) {
		documentElement.classList.add('is-modal-popup-open');
	} else {
		documentElement.classList.remove('is-modal-popup-open');
	}
}

const ModalPopup: React.FC<Props> = (props) => {
	const {isModal, onClose, open, children, header, className, theme, maxWidth, ...other} = props;
	const wrapper = useRef<HTMLDivElement | null>(null);

	const setFocus = useCallback(() => {
		if (wrapper && wrapper.current) {
			wrapper.current.focus();
		}
	}, []);

	const close = useCallback(() => {
		if (!isModal && onClose) onClose();
	}, [isModal, onClose]);

	useEffect(() => {
		toggleHtmlClass(open);

		return () => {
			if (open) toggleHtmlClass(false);
		};
	}, [open]);

	useEffect(() => {
		function checkEscapeKey(e: KeyboardEvent) {
			if (e.keyCode === 27 && open) close();
		}

		document.addEventListener('keyup', checkEscapeKey);

		return () => {
			document.removeEventListener('keyup', checkEscapeKey);
		};
	}, [open, close]);

	if (!open) return null;

	return (
		<Modal>
			<div className={cls(css.wrapper, className)} ref={wrapper} tabIndex={0}>
				<div className={cls(css.backdrop, 'modal-popup-backdrop in')} onClick={close} />
				<div className={css.container}>
					<div className={cls('modal-popup', css.popup)} style={maxWidth ? {maxWidth} : undefined}>
						<main className={css.popupMain} {...other} onClick={(event) => event.stopPropagation()}>
							{!isModal && (
								<button
									type="button"
									className={cls(css.close, 'modal-popup-close')}
									onClick={close}
									aria-label={'close'}
								>
									<SVGIcons.CrossIcon width={24} height={24} />
								</button>
							)}

							<h1 className={css.header}>{header}</h1>
							{children && (
								<section className={cls(css.content, 'modal-popup-content')}>{children}</section>
							)}
						</main>
					</div>
				</div>
			</div>
			{/*Что бы отловить уход из popup*/}
			<div tabIndex={0} onBlur={setFocus} />
			{/*Что бы фокус не пригнул в адресную строку (в этом случае его уже не поменять в chrome)*/}
			<div tabIndex={0} />
		</Modal>
	);
};

ModalPopup.defaultProps = {
	theme: 'info',
};

export default ModalPopup;
