import React, {useEffect, useRef, useState} from 'react';
import {throttle} from '../../utils/throttle';
import usePrevious from './usePreviews';

type Props = {
	throttleTimeout?: number;
	onResize?: (width: number, height: number) => void;
};

function useObserver(ref: React.RefObject<any>, options?: Props) {
	const {onResize, throttleTimeout = 100} = options || {};
	const [size, setSize] = useState<{width: number; height: number}>({width: 0, height: 0});
	const width = useRef<number>(0);
	const height = useRef<number>(0);

	const instance = useRef<ResizeObserver | null>(null);

	useEffect(() => {
		if (!ref.current) return;

		if (!instance.current) {
			instance.current = new ResizeObserver(
				throttle((entries: any) => {
					if (
						width.current !== entries[0].contentRect.width ||
						height.current !== entries[0].contentRect.height
					) {
						width.current = entries[0].contentRect.width;
						height.current = entries[0].contentRect.height;
						setSize({width: entries[0].contentRect.width, height: entries[0].contentRect.height});
					}
				}, throttleTimeout),
			);
		}
		instance.current.observe(ref.current);

		return () => {
			instance.current && instance.current.unobserve(ref.current);
		};
	}, [ref]);

	const previewsSize = usePrevious(size);
	useEffect(() => {
		if (onResize && size && previewsSize !== size) {
			onResize(size.width, size.height);
		}
	}, [onResize, size, previewsSize]);

	return size;
}

export default useObserver;
