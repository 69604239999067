import {useEffect, useState} from 'react';

let i = 1;
export default function uid() {
	return i++;
}
/* Метод нужен для того чтобы при серверном и клиентском рендере id совпадали */
export function resetUid() {
	i = 1;
}

export const useUniqueId = (prefix: string = '') => {
	const [id, setId] = useState<number | null>(null);

	useEffect(() => {
		setId(++i);
	}, []);

	return prefix + id;
};
