import React from 'react';
import ReactDOM from 'react-dom';

const modalRoot = process.browser ? document.body : (null as any);

class Modal extends React.Component<{}> {
	// Create a div that we'll render the modal into. Because each
	// Modal component has its own element, we can render multiple
	// modal components into the modal container.
	el = process.browser ? document.createElement('div') : null;

	componentDidMount() {
		// Append the element into the DOM on mount. We'll render
		// into the modal container element (see the HTML tab).
		modalRoot.appendChild(this.el);
	}

	componentWillUnmount() {
		// Remove the element from the DOM when we unmount
		modalRoot.removeChild(this.el);
	}

	render() {
		// Use a portal to render the children into the element
		if (!process.browser || !this.el) return null;

		return ReactDOM.createPortal(
			// Any valid React child: JSX, strings, arrays, etc.
			this.props.children,
			// A DOM element
			this.el,
		);
	}
}

export default Modal;
